import moment from 'moment';

export const salesLogHeader = [
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Email', key: 'email' },

  { label: 'Phone', key: 'phoneNumber' },
  { label: 'Address', key: 'address' },
  { label: 'Country', key: 'country' },

  { label: 'Status', key: 'status' },
  { label: 'Date Added', key: 'created_at' },
];

export const bbnLogHeader = [
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Email', key: 'email' },
  { label: 'Phone', key: 'phoneNumber' },
  { label: 'Address', key: 'address' },
  { label: 'State', key: 'state' },
  { label: 'Country', key: 'country' },
];

export const jacketsHeader = [
  { label: 'FullName', key: 'fullName' },
  { label: 'Email', key: 'email' },
  { label: 'Phone', key: 'phoneNumber' },
  { label: 'Address', key: 'address' },
  { label: 'Quantity', key: 'quantity' },
  { label: 'Size', key: 'size' },
  { label: 'Price', key: 'price' },
  { label: 'Reference', key: 'reference' },
  { label: 'Status', key: 'PaymentStatus' },
];

export const headers = [
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Payment REF', key: 'payment_reference' },
  { label: 'Status', key: 'status' },
  { label: 'Sub zone', key: 'name' },
  { label: 'Basestation', key: 'baseStation' },
  { label: 'Asset Zone', key: 'zone' },
  { label: 'State', key: 'state' },
];

export const failedHeaders = [
  { label: 'First Name', key: 'user.firstName' },
  { label: 'Last Name', key: 'user.lastName' },
  { label: 'Payment REF', key: 'payment_reference' },
  { label: 'Address', key: 'address' },
  { label: 'Email', key: 'user.email' },
  { label: 'Phone Number', key: 'user.phoneNumber' },
  { label: 'Zone', key: 'zone.name' },
  { label: 'State', key: 'state' },
];

export const relocationHeaders = [
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Email', key: 'email' },
  { label: 'Old Address', key: 'old_address' },
  { label: 'New Address', key: 'new_address' },
  { label: 'Phone Number', key: 'phoneNumber' },
];
export const zoneHeaders = [
  { label: 'Zone', key: 'name' },
  { label: 'Subzones', key: 'sub_zones[0].name' },
  { label: 'Date/Time', key: 'created_at' },
  { label: 'Daily Installation Slots', key: 'no_of_installations' },
];
export const feedbackHeaders = [
  { label: 'First Name', key: 'installation.user.firstName' },
  { label: 'Last Name', key: 'installation.user.lastName' },
  { label: 'Email', key: 'installation.user.email' },
  { label: 'Rating', key: 'rating' },
  { label: 'Experience', key: 'experience' },
  { label: 'Created At', key: 'created_at' },
];

export const assetTeamHeaders = [
  { label: 'MAC ID', key: 'username' },
  { label: 'Name', key: 'Name' },
  { label: 'Email', key: 'Mail' },
  { label: 'Phone', key: 'Mobile' },
  { label: 'Expiration Date', key: 'Expiration_date' },
  { label: 'Customer Number', key: 'Customer_Number' },
  { label: 'Status', key: 'subscription_status' },
  { label: 'Basestation', key: 'basestation' },
  { label: 'Zone', key: 'zone' },
  { label: 'State', key: 'region' },
  { label: 'Agent', key: 'agent' },
];
export const assetTeamHeaderSearch = [
  { label: 'MAC ID', key: 'username' },
  { label: 'Name', key: 'name' },
  { label: 'Email', key: 'mail' },
  { label: 'Phone', key: 'address' },
  { label: 'Expiration Date', key: 'value' },
  { label: 'Customer Number', key: 'customer_ref' },
  { label: 'Basestation', key: 'basestation' },
  { label: 'Zone', key: 'zone' },
  { label: 'State', key: 'region' },
  { label: 'Agent', key: 'agent' },
];

export const assetTeamHeadersRestricted = [
  { label: 'MAC ID', key: 'username' },
  { label: 'Name', key: 'Name' },
  { label: 'Email', key: 'Mail' },
  { label: 'Phone', key: 'Mobile' },
  { label: 'Expiration Date', key: 'Expiration_date' },
  { label: 'Customer Number', key: 'Customer_Number' },
  { label: 'Status', key: 'subscription_status' },
  { label: 'Basestation', key: 'basestation' },
  { label: 'Zone', key: 'zone' },
  { label: 'State', key: 'region' },
  { label: 'Agent', key: 'agent' },
  { label: 'Comment', key: 'comment' },
  { label: 'Comment Added At', key: 'comment_addedat' },
  { label: 'Comment Added By', key: 'comment_addedby' },
  { label: 'Details', key: 'details' },
];

export const BizdevlogHeaders = [
  { label: 'FirstName', key: 'firstName' },
  { label: 'LastName', key: 'lastName' },
  { label: 'Email', key: 'email' },
  { label: 'Phone', key: 'phoneNumber' },
  { label: 'Address', key: 'address' },
  { label: 'Status', key: 'status' },
  { label: 'City', key: 'city' },
  { label: 'Date', key: 'created_at' },
];

export const assetTeamHeaders2 = [
  { label: 'MAC ID', key: 'username' },
  { label: 'Name', key: 'name' },
  { label: 'Email', key: 'mail' },
  { label: 'Phone', key: 'address' },
  { label: 'Session Date', key: 'value' },
  { label: 'Customer Number', key: 'customer_ref' },
  { label: 'Status', key: 'subscription_status' },
  { label: 'Basestation', key: 'basestation' },
  { label: 'Zone', key: 'zone' },
  { label: 'State', key: 'region' },
  { label: 'Agent', key: 'agent' },
];

export const usageHistoryHeaders = [
  { label: 'MAC ID', key: 'username' },
  { label: 'Session Date', key: 'date' },
  { label: 'Upload(GB)', key: 'upload_gb' },
  { label: 'Download(GB)', key: 'download_gb' },
];

export const dedicatedCustomersHeaders = [
  { label: 'MAC ID', key: 'username' },
  { label: 'Name', key: 'name' },
  { label: 'Phone', key: 'mobile' },
  { label: 'Email', key: 'email' },
  { label: 'Basestation', key: 'basestation' },
  { label: 'Activation Status', key: 'activation_status' },
  { label: 'Status', key: 'status' },
  { label: 'Subscription Frequency', key: 'subscription_freq' },
  { label: 'Expiration Date', key: 'expiration' },
  { label: 'Country', key: 'country' },
  { label: 'Amount', key: 'price' },
  { label: 'Customer No', key: 'customer_number' },
];

export const partnershipUsageHistoryHeaders = [
  { label: 'Name', key: 'name' },
  { label: 'Age', key: 'age' },
  { label: 'Gender', key: 'gender' },
  { label: 'Email', key: 'email' },
  { label: 'Address', key: 'address' },
  { label: 'State', key: 'state' },
  { label: 'Date Created', key: 'created_at' },
  { label: 'Mobile', key: 'mobile' },
  { label: 'Mac ID', key: 'UserName' },
  { label: 'Acct Start Time', key: 'acctstarttime' },
  { label: 'Uploads (MB)', key: 'uploads' },
  { label: 'Downloads (MB)', key: 'downloads' },
];

export const partnershipSignupHistoryHeaders = [
  { label: 'Name', key: 'name' },
  { label: 'Age', key: 'age' },
  { label: 'Gender', key: 'gender' },
  { label: 'Email', key: 'email' },
  { label: 'Address', key: 'address' },
  { label: 'State', key: 'state' },
  { label: 'Date Created', key: 'created_at' },
  { label: 'Mobile', key: 'mobile' },
];

export const bulkCustomerDetailsHeaders = [
  { label: 'MAC ID', key: 'username' },
  { label: 'Name', key: 'name' },
  { label: 'Email', key: 'mail' },
  { label: 'Customer Reference', key: 'customer_ref' },
  { label: 'Basestation', key: 'basestation' },
  { label: 'Zone', key: 'zone' },
  { label: 'Agent', key: 'agent' },
  { label: 'Country', key: 'country' },
  { label: 'Region', key: 'region' },
  { label: 'Address', key: 'address' },
  { label: 'Subscription Status', key: 'subscription_status' },
  { label: 'Last Activation Time', key: 'lastactivation_time' },
  { label: 'Mobile Numnber', key: 'phone' },
  { label: 'Expiration Date', key: 'expiration_date' },
];

export const retentionLogsMessageHeader = [
  { label: 'MAC ID', key: 'username' },
  { label: 'Name', key: 'name' },
  { label: 'Customer Email', key: 'customer_email' },
  { label: 'Customer Phone', key: 'phone' },
  { label: 'Basestation', key: 'basestation' },
  { label: 'Message', key: 'message' },
  { label: 'Staff', key: 'agent' },
  { label: 'Time', key: 'time' },
];

export const retentionLogsCommentHeader = [
  { label: 'Customer Number', key: 'customer_number' },
  { label: 'MAC ID', key: 'username' },
  { label: 'Name', key: 'name' },
  { label: 'Customer Email', key: 'mail' },
  { label: 'Customer Phone', key: 'phone' },
  { label: 'Comment', key: 'comment' },
  { label: 'Details', key: 'details' },
  { label: 'Basestation', key: 'basestation' },
  { label: 'Staff', key: 'agent' },
  { label: 'Time', key: 'time' },
];

export const referralHeaders = [
  { label: 'Name', key: 'name' },
  { label: 'Phone Number', key: 'phoneNumber' },
  { label: 'Email ', key: 'email' },
  { label: 'Address', key: 'address' },
  { label: 'Customer Identification Number', key: 'cin' },
  { label: 'Status', key: 'status' },
  { label: 'Date/Time created', key: 'created_at' },
  { label: 'Customers Name', key: 'user.firstName' },
  { label: 'Closed By', key: '' },
];

export const bandwidthHeaders = [
  { label: 'Sites', key: 'basestation' },
  { label: 'IP Address', key: 'ip' },
  { label: 'Time', key: 'time' },
  { label: 'Upload Speed ', key: 'txspeed' },
  { label: 'Download Speed', key: 'rxspeed' },
  { label: 'Upload Total Average', key: 'txtotalaverage' },
  { label: ' Download Total Average', key: 'rxtotalaverage' },
];

export const employeeHeaders = [{ label: 'Email', key: 'email' }];
export const VehicleHeaders = [
  { label: 'Color', key: 'color' },
  { label: 'Make', key: 'make' },
  { label: 'Model ', key: 'model' },
  { label: 'Engine No', key: 'engine_no' },
  { label: 'Chasis no', key: 'chasis_no' },
  { label: 'Year of Purchase', key: 'year_of_purchase' },
  { label: 'Plate Number', key: 'plate_number' },
  { label: 'Transmission Type', key: 'transmission_type' },
  { label: 'Registration Number', key: 'registration_number' },
];

export const callReportHeaders = [
  { label: 'ID', key: 'id' },
  { label: 'Unique Call ID', key: 'unique_call_id' },
  { label: 'Customer CIN', key: 'customer_cin' },
  { label: 'Phone', key: 'customer_phone' },
  { label: 'Notes', key: 'notes' },
  { label: 'Staff Email', key: 'staff_email' },
  { label: 'Calling Ext', key: 'calling_ext' },
  { label: 'Status', key: 'status' },
  { label: 'Date/Time created', key: 'created' },
  { label: 'Date/Time updated', key: 'updated' },
  { label: 'Duration(s)', key: 'duration_in_seconds' },
];

export const customerRevenueHeaders = [
  { label: 'Debtor Number', key: 'debtor_no' },
  { label: 'Name', key: 'name' },
  { label: 'Mac ID', key: 'macaddress' },
  { label: 'Reference', key: 'reference' },
  { label: 'Counter', key: 'counter' },
  { label: 'Type Number', key: 'type_no' },
  { label: 'Transaction Date', key: 'tran_date' },
  { label: 'Account', key: 'account' },
  { label: 'Memo', key: 'memo_' },
  { label: 'Amount', key: 'amount' },
  { label: 'Zone', key: 'zone' },
  { label: 'Basestation', key: 'basestation' },
];

export const mailBlastHeaders = [
  { label: 'Name', key: 'Name' },
  { label: 'MAC ID', key: 'username' },
  { label: 'Email', key: 'Mail' },
  { label: 'Phone', key: 'Mobile' },
  { label: 'Customer Number', key: 'Customer_Number' },
  { label: 'Status', key: 'subscription_status' },
  { label: 'Expiration Date', key: 'Expiration_date' },
  { label: 'Created At', key: 'created_at' },
];

export const clusteredInstallationHeaders = [
  { label: 'Name', key: 'customer_name' },
  { label: 'Address', key: 'address' },
  { label: 'Status', key: 'status' },
  { label: 'Reference Point', key: 'zone' },
  { label: 'Zone', key: 'zone_name' },
  { label: 'Team', key: 'team_name' },
  { label: 'FSE Staff', key: 'team' },
  { label: 'Device Type', key: 'deviceType' },
  { label: 'Sales Agent', key: 'salesAgent' },
];

export const installationCounterHeaders = [
  { label: 'Name', key: 'fullName' },
  { label: 'Email', key: 'email' },
  { label: 'Phone', key: 'phoneNumber' },
  { label: 'Status', key: 'status' },
  { label: 'address', key: 'address' },
  { label: 'Zone', key: 'name' },
];

export const refundHeaders = [
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Email', key: 'email' },
  { label: 'Phone Number', key: 'phoneNumber' },
  { label: 'Address', key: 'address' },
  { label: 'State', key: 'state' },
  { label: 'Bank Name', key: 'bank_name' },
  { label: 'Account Name', key: 'account_name' },
  { label: 'Account Number', key: 'account_number' },
  { label: 'Refund Status', key: 'refund_status' },
  { label: 'Note', key: 'note' },
  { label: 'Payment Ref', key: 'payment_ref' },
  { label: 'FSE Comment', key: 'fse_comment' },
  { label: 'Sales Comment', key: 'sales_comment' },
  { label: 'Coo Comment', key: 'coo_comment' },
  { label: 'Account Comment', key: 'account_comment' },
];

export const filename = (file) => {
  return `${file} ${moment(new Date()).format('DD-MMM-YYYY hh-mm A')}.csv`;
};
export const StatusData = [
  {
    name: 'Awaiting Account Creation',
    value: 'awaiting_account_creation',
  },
  {
    name: 'Failed',
    value: 'failed',
  },
  {
    name: 'Not Completed',
    value: 'not completed',
  },
  {
    name: 'No LOS',
    value: 'no_los',
  },
  {
    name: 'On-Going',
    value: 'on-going',
  },
  {
    name: 'Queued',
    value: 'queued',
  },
  {
    name: 'Rescheduled',
    value: 'rescheduled',
  },
  {
    name: 'Completed',
    value: 'completed',
  },
];

export const Status = [
  {
    name: 'true',
    value: 'true',
  },
];
export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
